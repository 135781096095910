import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component:  () => import('../views/LoginView.vue')
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    }
    ,
    {
        path: '/config',
        name: 'config',
        component: () => import('../views/ConfigView.vue')
    },
    {
        path: '/admin/login',
        name: 'adminLogin',
        component: () => import('../admin/adminLoginView.vue')
    }
    ,
    {
        path: '/admin/',
        name: 'adminRoot',
        component: () => import('../admin/adminRoot.vue')
    } ,
    {
        path: '/admin/userManger',
        name: 'userManger',
        component: () => import('../admin/adminUserMangerView.vue')
    }
]


const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'login' && to.name !=="adminLogin"&&  !localStorage.getItem('isadmin') &&  !localStorage.getItem('token')) next({ name: 'login' })
    else next()
    if(to.name === 'login' && to.name ==="adminLogin" &&  localStorage.getItem('isadmin')  && localStorage.getItem('token')) next({ name: 'config' })
    else next()
})


export default router
