<template>
  <div id="app">
    <el-row class="center-row">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="center-col">
        <router-view></router-view>
      </el-col>
    </el-row>

  </div>
</template>

<script>


export default {
  name: 'app',
  components: {

  },
  created() {
    this.$http.get("/getUserConf").then((res)=>{
      if(res.data.code ===0){
        document.title = res.data.data.title;
      }
    })

  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  /*margin-top: 60px;*/
}
.center-row {
  display: flex;
  justify-content: center;
}
.center-col {
  max-width: 100%;
}
@media (min-width: 992px) {
  .center-col {
    max-width: 100%;
  }
}
.el-message-box {
  width: 320px !important;
}
</style>
